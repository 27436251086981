@import './theme.scss';

.main-container {
    @include themed() {
        .content-box.form-container {
            position: relative;
            form {
                display: flex;
                flex-flow: column;
                align-items: stretch;
                height: 100%;
                padding-top: 15px;
                .MuiFormControl-root {
                    width: 100%;
                    .MuiOutlinedInput-root {
                        margin: 5px 0;
                        input {
                            box-sizing: inherit;
                            font-size: 14px;
                            color: t('text');
                            background-color: t('text-field-color');
                            border: 1px solid t('text-field-color');
                            border-radius: 32px;
                            height: 50px;
                        }
                        fieldset {
                            display: none;
                        }
                    }
                    .MuiInputBase-multiline {
                        box-sizing: inherit;
                        font-size: 12px;
                        color: t('text');
                        background-color: t('bg');
                        border: 1px solid t('text');
                        border-radius: 4px;
                    }
                }
                .address-list {
                    margin: 10px 0;
                    max-height: 50px;
                    overflow: auto;
                    overflow-y: hidden;
                    white-space: nowrap;
                    div.MuiButtonBase-root {
                        margin: 0 5px 5px 0;
                        background-color: t('bg');
                        color: t('text');
                        border: 1px solid t('text');
                        svg {
                            color: #d64933;
                        }
                    }
                } 
                .estimated-reward {
                    top: 933px;
                    left: 514px;
                    width: 255px;
                    height: 23px;
                    text-align: left;
                    letter-spacing: 0px;
                    color: t('bar-color');
                    opacity: 1;
                    margin-top: 12px;
                    margin-left: 35px;
                }
                .yellow-bar {
                    position: absolute;
                    top: 122px;
                    width: 90%;
                    transform: translateX(-5%);
                    left: 10%;
                    border: 4px solid #F5B700;
                    opacity: 0.8;
                    border-radius: 13px;
                }
                .wrapper {
                    background-color: t('text-field-color');
                    border-radius: 25px 25px 0 0px;
                    margin-bottom: 4vh;
                    position: relative;
                    flex: 1 1 auto;
                    max-height: 50vh;
                
                    .rdw-editor-toolbar {
                        border-radius: 0 0 25px 25px;
                        border: none;
                        width: 100%;
                        position: absolute;
                        bottom: -25px;
                        margin: 0;
                        z-index: 2;
                        background: t('toolbar-bg');
                        .rdw-option-wrapper, 
                        .rdw-dropdown-wrapper {
                            border: none;
                            background-color: transparent;
                        }
                        .rdw-embedded-modal, .rdw-emoji-modal, 
                        .rdw-option-wrapper, .rdw-link-modal,
                        .rdw-colorpicker-modal,
                        .rdw-image-modal {
                            top: -235px;
                        }
                        .rdw-dropdown-optionwrapper {
                            top: -200px;
                            max-height: 170px;
                            li {
                                color: #000;
                            }
                        }
                        .rdw-link-modal *,
                        .rdw-dropdown-selectedtext {
                            color: #000;
                        }
                    }
                }
                .editor {
                    padding: 15px;
                    overflow-y: auto;
                    .public-DraftEditor-content > div  {
                        padding-bottom: 50px;
                    }
                }

                .form-bottom {
                    position: relative;
                    flex: 0 1 60px;
                    display: flex;
                    justify-content: flex-end;
                    .send-btn {
                        font-size: 16px;
                        background-color:  #F5B700;
                        color: #000;
                        font-weight: bold;
                        border-radius: 25px;
                        padding: 15px auto;
                        width: 150px;
                        position: absolute;
                        text-transform: none;
                        z-index: 9;
                        .MuiLoadingButton-loadingIndicator {
                            color: black;
                            margin: 0 0 0 5px;
                        }
                        
                    }
                    .send-papper-airplane {
                        margin-right: 17px;
                        height: 15px;
                    }
                    .MuiLoadingButton-loadingIndicator {
                        color: t('text');
                        position: relative;
                        left: 0;
                        transform: none;
                    }
                    .rewards {
                        position: absolute;
                        margin-top: 10px;
                        right: 25px;
                        p {
                            font-weight: bold;
                            color: #F5B700;
                        }
                    }
                }

            }
        }
    }
}

@media (min-width: 769px) {
    .form-container {
        @include themed() {
            form {
                .address-list {
                    display: inline-block;
                }
                .wrapper {
                    margin-bottom: 0;
                }
            }
        }
    }    
}